<template>
    <div class="sign-in h-100">
        <register></register>
    </div>
</template>

<script>
    import Register from "~/components/Register"

    export default {

        head () {
            return {
                title: 'Confestetica - Centri Estetici, Istituti di Bellezza, Corsi Estetica',
                meta: [
                    // hid is used as unique identifier. Do not use `vmid` for it as it will not work
                    {
                        name: 'title',
                        content: 'Confestetica - Centri Estetici, Istituti di Bellezza, Corsi Estetica'
                    },
                    {
                        hid: 'description',
                        name: 'description',
                        content: 'Associazione nazionale maggiormente rappresentativa dei Centri Estetici. 20.000 sono le estetiste associate e certificate dal 2007'
                    },
                    // {
                    //     name: 'og:image',
                    //     content: ''
                    // },
                    {
                        hid: 'keywords',
                        name: 'keywords',
                        content: 'centro estetico, scuola estetica, corsi estetista, pacchetti benessere, trattamenti estetici'
                    },
                ]
            }
        },

        auth: false,

        async fetch ({ store, route }) {
            await Promise.all([
                store.dispatch("accounts/fetchCount", {
                    page: route.query.page,
                    filters: {
                        type: "beauty-centre",
                        status: "published"
                    }
                }),
                store.dispatch("users/fetchCount", {
                    page: route.query.page,
                    filters: {
                        status: "published"
                    }
                })
              ])
        },

        layout: 'responsive_no_bottom',

        middleware: ['redirect-if-authenticated'],

        components: {
            Register
        },

        methods: {
        }
    }
</script>

<style lang="scss">
    .register-image {
        max-width: 100%;
        position: absolute;
        bottom: -25px;
        left: 0;
        right: 0;
    }
</style>
